import React from 'react';
import Footer from './../Footer.js'

export default function Faq(props) {
  return <div id="faq">

    <h2>FAQ - Lightning Bug 2</h2>
    <p>Frequently asked questions for Lightning Bug 2 for Android</p>

    <ul className="questions">
      <li><a href="#audio-skipping">I hear noticeable gaps, or skipping, in Lightning Bug's audio, what should I do?</a></li>
      <li><a href="#alarm-failed">I set Lightning Bug's alarm, why didn't it go off?</a></li>
      <li><a href="#sound-volume">How do I make the sounds louder?</a></li>
      <li><a href="#sound-frequency">How do I change the frequency of sounds?</a></li>
      <li><a href="#sleep-timer">How do I set the application to turn off after I am asleep?</a></li>
      <li><a href="#screen-timeout">How do I turn off the screen but keep the sounds?</a></li>
      <li><a href="#receive_boot_complete">Why does Lightning Bug start when my phone boots?</a></li>
      <li><a href="#beach-at-night">I just upgraded to Plugin Mode, where’s Beach at Night?</a></li>
      <li><a href="#background">Why is Lightning Bug always running in the background?</a></li>
    </ul>


    <h3><a name="audio-skipping" href="#audio-skipping">I hear noticeable gaps, or skipping, in Lightning Bug's audio, what should I do?</a></h3>
    <p>
      This is a known problem that goes back to Android 2.3 Gingerbread. 
      While we have made great efforts to provide you with a quality audio experience, we do continue to get ocassional reports.
    </p>
    <p>
      If you hear gaps, skipping, or other audio problems while using Lightning Bug, 
      please contact <a href="mailto:support@lightningbug.com">support@lightningbug.com</a>
      and make note of the following:
    </p>
    <ul>
      <li>Which specific sounds are skipping?</li>
      <li>Are you streaming audio via Bluetooth?</li>
      <li>Is the problem recent or has it been ongoing?</li>
    </ul>


    <h3><a name="alarm-failed" href="#alarm-failed">I set Lightning Bug's alarm, why didn't it go off?</a></h3>
    <p>
      There are a few reasons that Lightning Bug's alarm may fail to go off on schedule. 
      Please understand that regardless the cause, you should test *any* alarm app on your phone before relying on it to get you to work or class.
    </p>
    <ul>
      <li>Are you running any 'task killer' apps? And if so, is Lightning Bug in that app's whitelist?</li>
      <li>Have you install Lightning Bug to your SD card? If so, the alarm may fail to go off if your device was restarted after the alarm was set.</li>
      <li>Has Lightning Bug's alarm worked in the past, and was the alarm actually set this time around? Please make sure, you'd be surprised!</li>
    </ul>


    <h3><a name="sound-volume" href="#sound-volume">How do I make the sounds louder?</a></h3>
    <p>Long press each sound and then a volume control menu will open.</p>


    <h3><a name="sound-frequency" href="#sound-frequency">How do I change the frequency of sounds?</a></h3>
    <p>If you have Plugin Mode enabled, you can also change sound frequency by long pressing the sound's button in the menu. This feature is only available in Plugin Mode.</p>


    <h3><a name="sleep-timer" href="#sleep-timer">How do I set the application to turn off after I am asleep?</a></h3>
    <p>In the settings menu, make use of the screen timeout feature.</p>


    <h3><a name="received_boot_complete" href="#received_boot_complete">Why does Lightning Bug start when my phone boots?</a></h3>
    <p>
      Lightning Bug must run when your phone boots so that it can ensure its alarm system is configured correctly. 
      Otherwise it's possible alarms you have set fail to go off at the right time. 
      That said, only a very small portion of the Lightning Bug app executes at boot 
        so it will not significantly effect your device's start up time.
    </p>


    <h3><a name="beach-at-night" href="#beach-at-night">I just upgraded to Plugin Mode, where's Beach at Night?</a></h3>
    <p>
      Beach at Night is still available in Plugin Mode, but you must purchase the Beach at Night plugin pack. 
      Open the Plugin Manager and check it out!
    </p>


    <h3><a name="background" href="#background">Why is Lightning Bug always running in the background?</a></h3>
    <p>
      Unless you actually hear Lightning Bug playing sounds, it will only run in the background when your device synchronizes its internal clock with your network provider. 
      When this occurs, Lightning Bug only runs very briefly to update its alarm system. 
      So while it may appear Lightning Bug is running in the background, chances are it is just loaded in memory. 
      This is normal behavior for Android apps and does not indicate a problem or significant load on your device’s CPU or battery.
    </p>


    <Footer hide={props.hideFooter} />

  </div>;
}
