import React from 'react';
import Footer from './Footer.js'

export default function Terms(props) {
  return <div id="terms">


    <h2>Terms and Conditions</h2>
    <p>END USER LICENSE AGREEMENT</p>
    <p>BY USING THE LIGHTNING BUG WEBSITE (https://lightningbug.com) OR BY DOWNLOADING AND USING THE LIGHTNING BUG MOBILE APPLICATION YOU ACCEPT AND AGREE TO THESE TERMS AND CONDITIONS.</p>

    <p>This End User License Agreement (the "Agreement") for Lightning Bug (together with any updates, the "Application") is a legal agreement between user ("You" or "Your"), and TECHNICA SOFTWARE, LLC ("Technica Software"). By accessing, downloading, copying or otherwise using the Application, You acknowledge that You have read this Agreement, understand it, and agree to be bound by its terms and conditions. If You do not agree to the terms and conditions of this Agreement, do not access, download, copy or use the Application. Technica Software will not and does not grant You access to the Application unless You agree to the terms of this Agreement.</p>

    <p>In consideration of the promises and covenants described below, and other good and valuable consideration, You agree as follows:</p>

    <p>1.	License Grant; Compliance with Terms of Use. The Application is licensed, not sold, and Technica Software reserves all rights not expressly granted in this Agreement. Subject to the terms and conditions hereof, Technica Software grants You a personal, nonexclusive, nontransferable, non-sublicenseable, limited license to download and use the Application in a web browser or on a mobile device that You own or control.</p>

    <p>2.	License Restrictions. Except as specifically provided herein, You may not: (i) distribute or make the Application available over a network where it could be used by multiple devices at the same time; (ii) copy the Application; (iii) modify, adapt, translate, reverse engineer, make alterations, decompile, disassemble or make derivative works based on the Application, except as otherwise permitted by law; or (iv) use, rent, loan, sub-license, lease, distribute or attempt to grant other rights to the Application to third parties.</p>

    <p>3.	Ownership. All of the content featured or displayed in or through the Application ("Content") and all trademarks, service marks and trade names of Technica Software included therein, are owned by Technica Software, its licensors, vendors, agents and/or its Content providers. All Content is subject to Technica Software's Terms of Use, as may be amended from time to time, and as incorporated herein by this reference.</p>

    <p>4.	Your Warranty to Technica Software. You represent and warrant that: (i) You have the authority to bind Yourself to this Agreement; (ii) Your use of the Application will be solely for purposes that are permitted by this Agreement; and (iii) Your use of the Application will comply with all local, state and federal laws, rules, and regulations ("Laws").</p>

    <p>5.	Privacy. By using the Application, you agree that Technica Software may collect and use certain information about your use of the Application and the Application's performance in accordance with the Privacy Policy, as may be amended from time to time and incorporated herein by this reference.</p>

    <p>6.	Disclaimer of Warranties and Indemnification. Technica Software will not be liable for losses or damages arising from or in any way related to your access or use of the Application. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Technica Software IS LICENSING THE APPLICATION "AS IS," "AS AVAILABLE," AND "WITH ALL FAULTS." Technica Software MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY, RELIABILITY, TIMELINESS, AND ACCURACY, FOR ANY PURPOSE, OF THE APPLICATION, THE OPERATION OF THE APPLICATION ALONE OR IN CONJUNCTION WITH ANY DEVICE, OR THE CONTENT CONTAINED HEREIN. Technica Software DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, REGARDING THE APPLICATION AND ITS OPERATION AND EXPRESSLY DISCLAIMS THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>

    <p>7.	Limitation of Liability. TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL Technica Software BE LIABLE FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL OR OTHER RELATED OR SIMILAR DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, AND THE LIKE CONNECTED WITH THE USE OF OR INABILITY TO USE THE APPLICATION, AND FOR ANY CAUSE OF ACTION, INCLUDING CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, EVEN IF Technica Software HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ANY DAMAGES ARISING UNDER THIS AGREEMENT OR THE USE OF THE APPLICATION THAT Technica Software IS REQUIRED TO PAY FOR ANY PURPOSE WHATSOEVER, INCLUDING WITHOUT LIMITATION, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, SHALL BE LIMITED TO TWENTY FIVE DOLLARS ($25.00). SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU UNDER THE LAWS OF THOSE STATES. No action, regardless of form, whether in court or through arbitration, arising out of any transaction under this Agreement, may be brought by You more than one year after You have knowledge of the occurrence which gives rise to the cause of such action.</p>

    <p>8.	Indemnity. You agree to indemnify and hold harmless Technica Software and its affiliates, officers, directors, employees, consultants, agents and anyone providing information or software used in the Application from any and all claims arising from, related to, or incidental to Your use of the Application.</p>

    <p>9.	Termination. This Agreement is effective until terminated. Technica Software may immediately terminate this Agreement at any time at its sole discretion with or without notice to you. Additionally, Your rights under this Agreement will terminate automatically if you fail to comply with any term(s) of this Agreement. Upon termination, all legal rights and licenses granted to You hereunder shall terminate immediately and You shall cease all use of the Application and destroy all copies of the Application. All sections that may be reasonably interpreted to or are intended to survive this Agreement will survive this Agreement.</p>

    <p>10.	Governing Law. This Agreement shall be governed by the laws of the State of Virginia in the United States, without giving effect to the State of Virginia's choice of law principles. You irrevocably consent to the exclusive jurisdiction and venue of the state or federal courts in Alexandria, Virginia, USA for all disputes arising out of or relating to this Agreement. If any action is brought to enforce, or arises out of, the Agreement or any term, clause, or provision hereof, the prevailing party shall be awarded its reasonable attorney's fees together with expenses and costs incurred with such action.</p>

    <p>11.	Acknowledgment of Understanding/Entire Agreement. You acknowledge that You have read this Agreement, understand it and agree to be bound by its terms and conditions. You also agree that this Agreement is the complete and exclusive statement of the Agreement between Technica Software and You and supersedes all proposals, representations or prior agreements, oral or written, and any other communications between Technica Software and You relating to the subject matter of this Agreement.</p>

    <p>12.	Severability. You agree that the terms and conditions stated in this Agreement are severable. If any paragraph, provision, or clause in this Agreement shall be found or be held to be invalid or unenforceable in any jurisdiction, the remainder of this Agreement shall be valid and enforceable.</p>

    <p>13.	Assignment and Transfer. Technica Software may assign, transfer, sell, rent or lend this Agreement, in whole or in part, at any time without notice to You. You may not assign this Agreement or any part of it or any rights to use the Application, in whole or in part, either temporarily or permanently, to any other party. Any attempt to do so is void.</p>

    <p>14.	Amendment of this Agreement. Technica Software RESERVES THE RIGHT TO MODIFY OR AMEND THIS AGREEMENT FROM TIME TO TIME WITHOUT NOTICE. YOUR CONTINUED USE OF THE APPLICATION FOLLOWING THE POSTING OF CHANGES TO THE AGREEMENT WILL MEAN YOU ACCEPT THOSE CHANGES.</p>

    <p>BY USING THE LIGHTNING BUG WEBSITE (https://lightningbug.com) OR BY DOWNLOADING AND USING THE LIGHTNING BUG MOBILE APPLICATION YOU ACCEPT AND AGREE TO THESE TERMS AND CONDITIONS.</p>


    <Footer hide={props.hideFooter} />


  </div>;
}
