import React from 'react';

export default function Footer(props) {
  if(props.hide){
    return "";
  }else{
    return <footer>
      <nav>
        <a href="/">Home</a>
        <a href="/help">Help</a>
        <a href="/faq">FAQ</a>
        <a href="/privacy">Privacy</a>
        <a href="/terms">Terms</a>
      </nav>
      <div className="copyright">&copy; {new Date().getFullYear()} Technica Software</div>
    </footer>;
  }
}
