import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Home from './Home.js'
import Help from './v2/Help.js'
import Faq from './v2/Faq.js'
import Terms from './Terms.js'
import Privacy from './Privacy.js'
import SoundCatalog from './v2/SoundCatalog.js'

import * as firebase from "firebase/app";
import "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyDk7OljWWiBcReuNBC3aZFsG8-NQHznVm0",
  authDomain: "lightningbug2019-282b5.firebaseapp.com",
  databaseURL: "https://lightningbug2019-282b5.firebaseio.com",
  projectId: "lightningbug2019-282b5",
  storageBucket: "lightningbug2019-282b5.appspot.com",
  messagingSenderId: "604580177023",
  appId: "1:604580177023:web:79b66ba938fe10ce93cb4a",
  measurementId: "G-Q53C7TMEXS"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default function App() {
  return (
    <Router>
      <Switch>

        {/* legacy routes */}
        <Route path="/help/faq">
          <main><Faq hideFooter /></main>
        </Route>
        <Route path="/help/privacy">
          <main><Privacy /></main>
        </Route>
        <Route path="/help/terms">
          <main><Terms /></main>
        </Route>
        <Route path="/v2/faq">
          <main><Faq hideFooter /></main>
        </Route>
        <Route path="/v2/help">
          <main><Help hideFooter /></main>
        </Route>

        <Route path="/faq">
          <main><Faq /></main>
        </Route>
        <Route path="/help">
          <main><Help /></main>
        </Route>
        <Route path="/soundcatalog">
          <main><SoundCatalog /></main>
        </Route>
        <Route path="/privacy">
          <main><Privacy /></main>
        </Route>
        <Route path="/terms">
          <main><Terms /></main>
        </Route>
        <Route path="/">
          <div id="home-bg"><main><Home /></main></div>
        </Route>
      </Switch>
    </Router>
  );
}