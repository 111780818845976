import React from 'react';
import Footer from './../Footer.js'

export default function Help(props) {
  return <div id="help">

    <h2>Help - Lightning Bug 2</h2>
    <p>Having a problem with Lightning Bug 2 for Android?</p>
    <ul>
      <li>First, please check out the <a href="/faq">FAQ</a>.</li>
      <li>If you still have questions or need to request a refund, please email <a href="mailto:support@lightningbug.com">support@lightningbug.com</a></li>
    </ul>


    <Footer hide={props.hideFooter} />


  </div>;
}
