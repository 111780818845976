import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import logo from './images/logo.png';
import Footer from './Footer.js'

export default function Home() {
  return <div id="home">
    <div id="heyo">
      <img src={logo} alt="logo"></img>
      <h1>Lightning Bug</h1>
      <p className="pitch">The easy way to chill</p>
    </div>


    {/* <!-- Begin Mailchimp Signup Form --> */}

		<div id="mc_embed_signup">
			<form action="https://lightningbug.us4.list-manage.com/subscribe/post?u=c749cfdfce9580d7fda9b6afc&amp;id=6333072548" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
				<div id="mc_embed_signup_scroll">
					<div className="mc-field-group">
						<label htmlFor="mce-EMAIL">A new Lightning Bug is on the way <div>Want to know more?</div></label>
						<input type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="email address"></input>
						<input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"></input>
					</div>
					<div id="mce-responses" className="clear">
						<div className="response" id="mce-error-response" style={{display:'none'}}></div>
						<div className="response" id="mce-success-response" style={{display:'none'}}></div>
					</div>
          {/*<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->*/}
					<div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
						<input type="text" name="b_c749cfdfce9580d7fda9b6afc_6333072548" tabIndex="-1"></input>
					</div>
				</div>
			</form>
		</div>

    {/* <!--End mc_embed_signup--> */}


		<ul className="three-acts">
			<li>
				<span className="material-icons-round">volume_up</span>
				Big library of exclusive white noise and nature sounds</li>
			<li>
				<span className="material-icons-round">tune</span>
				Mix sounds and create the right white noise for you.</li>
			<li>
				<span className="material-icons-round">star</span>
				Millions of downloads and over 25,000 <br />5-Star reviews</li>
		</ul>


		<Carousel className="testimonials" autoPlay={true} showArrows={false} showStatus={false}>
			<div>
				<h3><b>Time Magazine</b> - The Best Websites to Help You Fall Asleep</h3>
				Lightning Bug provides relaxing nature sounds that will help you sleep better at night. Make sure to enable plug-ins and download the free White Noise pack... also comes with an alarm, snooze button and sleep timer.
				<br /><br /><a target="_blank" href="https://time.com/3847719/websites-help-fall-asleep/" rel="noopener noreferrer">Read more</a>
			</div>
			<div>
				<h3><b>Cracked</b> - 5 Products That Allow You to Master Your Dreams</h3>
				The first stage in mastering your dreams is getting to them... [Lightning Bug] delivers: You control the frequency, volume, number and order of all the settings. Amongst such tamer options as rainstorms, waves, crickets and traffic, you can find basically any other conceivable sound loop: sirens, jungle noises, trains...
				<br /><br /><a target="_blank" href="https://www.cracked.com/blog/5-products-that-allow-you-to-master-your-dreams/" rel="noopener noreferrer">Read more</a>
			</div>
			<div>
				<h3><b>Parents</b> - 5 Best Baby Sleep Apps for a Better Bedtime</h3>
				Including everything from rainstorms and waves to electronic instruments — Lightning Bug is a white-noise mixer that's bound to have the sound that will help your baby fall fast asleep...and stay asleep. The alarm and snooze prevent you from falling asleep while your baby's still awake.
				<br /><br /><a target="_blank" href="https://www.parents.com/toddlers-preschoolers/sleep/schedule/10-best-sleep-apps-for-better-bedtime/" rel="noopener noreferrer">Read more</a>
			</div>
			<div>
				<h3><b>SleepingOcean</b> - Top 10 Best Sleep Tracking Apps</h3>
				Looking for another way to chill before bed? Consider the Lightning Bug, an app that has a ton of relaxing sounds to offer. You can choose among white noise, ambient melodies, nature sounds, and more.
				<br /><br /><a target="_blank" href="https://www.sleepingocean.com/best-sleep-tracking-apps/" rel="noopener noreferrer">Read more</a>
			</div>
			<div>
				<h3><b>PC Magazine</b> - Sleep Better with Lightning Bug for Android</h3>
				Instead of just generating white noise with a static display on-screen, Lightning bug creates an audio and video landscape designed to help you relax and fall asleep.
				<br /><br /><a target="_blank" href="https://in.pcmag.com/opinion/20636/sleep-better-with-lightning-bug-for-android" rel="noopener noreferrer">Read more</a>
			</div>
			<div>
				<h3><b>Tuck</b> - Top Sleep Apps</h3>
				Lightning Bug is a white noise library for children featuring over 200 sounds from nature, white noise, and acoustic and electronic instruments.
				<br /><br /><a target="_blank" href="https://www.tuck.com/top-sleep-apps/" rel="noopener noreferrer">Read more</a>
			</div>
		</Carousel>


		<div className="awards">
			<a href="https://www.sleepingocean.com/best-sleep-tracking-apps/" target="_blank" rel="noopener noreferrer">
				<img src="images/Best Sleep Application SO.png" alt="SleepingOcean Best Sleep App 2021" />
			</a>
		</div>


    <p className="v2-android">
      Looking for Lightning Bug 2 on Android?
			<a href='https://play.google.com/store/apps/details?id=com.media1908.lightningbug&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
				<img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
			</a>
    </p>


    <Footer />


  </div>;
}
