import React from 'react';
import Footer from './Footer.js'

export default function Privacy(props) {
  return <div id="privacy">


    <h2>Privacy Policy</h2>
    <p>As you use Lightning Bug we want you to be clear on what information about you we use and how we use it. This Privacy Policy explains:</p>
    <ul>
        <li>What information we collect</li>
        <li>Why we collect that information</li>
        <li>How we use that information</li>
        <li>The control you have over that information</li>
    </ul>
    <p>We will keep this policy as simple as possible. You're questions and feedback are always welcome: <a href="mailto:support@lightningbug.com">support@lightningbug.com</a></p>


    <h3>Unless you subscribe or create an account, we do not collect any personally identifiable information about you.</h3>
    <p>In other words, we do not collect any personal information such as your name, location, phone number or email address.</p>

    <p>We only collect anonymous information. In order to provide a better user experience we gather metrics on how you use Lightning Bug. This information takes the form of aggregates such as how often you click a particular button or load a particular screen.</p>


    <h3>What if you create an account or subscribe to one of our email lists?</h3>
    <p>By subscribing to one of our email lists you have shared your email address with us. We will not share your email address with any other parties. You can opt-out from an email list at any time by clicking the unsubscribe button found at the bottom of any email sent to you by the list. By unsubscribing, your email address will be permanently deleted from the list.</p>

    <p>When you create a Lightning Bug account we'll only ask for your email address and a username. When you create content with Lightning Bug we save that data in a cloud environment. We do not share it with third parties. You can download your data from your Lightning Bug account page. You can cancel your account at any time. You can also request to have your account, and all associated data, deleted.</p>


    <h3>Changes</h3>
    <p>Our Privacy Policy may change from time to time. We will not reduce your rights under this Privacy Policy without your consent. We will post any privacy policy changes publicly. If the changes are significant, we will post a prominent notice.</p>


    <Footer hide={props.hideFooter} />


  </div>;
}
